import { Button, FloatingLabelInput, UploadIcon } from 'components';
import { Spin, Upload } from 'antd';
import styles from './ApplyForJob.module.scss';
import { useState } from 'react';

import { useApplyForJobMutation } from 'features/landing/services/landingApi';
import { useParams } from 'react-router-dom';

export default function ApplyForJobForm() {
  const [applyForJob, { isLoading }] = useApplyForJobMutation();
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const params = useParams();

  const [formItems, setFormItems] = useState({
    jobId: Number(params?.id),
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    minSalary: '',
    coverLetter: '',
    cvFile: undefined as File | undefined,
  });
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.currentTarget;
    if (name === 'phoneNumber' && value.length > 11) return;
    setFormItems({
      ...formItems,
      [e.target.name]: e.target.value,
    });
  };

  const onFileChange = (e: any) => {
    if (e.file) {
      setFormItems({
        ...formItems,
        cvFile: e.fileList.length ? e.file : undefined,
      });
    }
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();
    Object.entries(formItems).forEach(([key, value]) => {
      if (value) {
        formData.append(key, value as string | Blob);
      }
    });

    await applyForJob(formData).then((res) => {
      if ('data' in res) {
        setIsSubmitted(true);
      }
    });
  };
  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    console.log(emailRegex.test(email));
    return emailRegex.test(email);
  };
  const isFormValid = () => {
    const { firstName, lastName, email, phoneNumber, cvFile } = formItems;
    return (
      firstName &&
      lastName &&
      email &&
      validateEmail(email) && // Valid email check
      phoneNumber &&
      cvFile
    );
  };

  return (
    <div>
      {isSubmitted ? (
        <SuccessMessage />
      ) : (
        <div
          className={styles.applyForJobFormContainer}
          style={{
            maxWidth: 500,
            marginInline: 'auto',
          }}
        >
          <h1 className={styles.applyForJobFormTitle}>Apply for The Job</h1>
          <form
            onSubmit={onSubmit}
            style={{
              display: 'grid',
              gap: 16,
              paddingBottom: 40,
            }}
          >
            <FloatingLabelInput
              onChange={handleChange}
              name="firstName"
              label="First name"
              required
            />
            <FloatingLabelInput
              onChange={handleChange}
              name="lastName"
              label="Last name"
              required
            />
            <FloatingLabelInput
              onChange={handleChange}
              name="email"
              label="Email Address"
              required
              id="email"
              type="email"
            />
            <FloatingLabelInput
              onChange={handleChange}
              name="phoneNumber"
              label="Phone Number"
              required
              type="number"
              placeholder="+374-91-xx-xx-xx"
              value={formItems.phoneNumber}
            />
            <FloatingLabelInput
              onChange={handleChange}
              name="minSalary"
              label="Minimum salary"
              type="number"
            />

            <Upload
              multiple={false}
              onChange={onFileChange}
              accept=".pdf"
              beforeUpload={() => false}
              listType="picture-card"
              style={{
                borderRadius: 32,
              }}
            >
              {!formItems.cvFile ? (
                <button
                  style={{
                    border: 0,
                    background: 'none',
                    borderRadius: 32,
                    paddingBottom: 10,
                    paddingInline: 10,
                  }}
                  type="button"
                >
                  <UploadIcon fill="var(--text-color)" />
                  <p style={{ marginTop: 8, color: 'var(--text-color)' }}>
                    {'Choose your CV/resume file or drag it here.\n(.pdf)'}
                    <span
                      style={{
                        color: 'red',
                      }}
                    >
                      *
                    </span>
                  </p>
                </button>
              ) : null}
            </Upload>

            <FloatingLabelInput
              name="coverLetter"
              onChange={handleChange}
              style={{
                minWidth: '100%',
              }}
              label="Cover letter"
              inputType="textarea"
              rows={4}
            />

            {isLoading ? (
              <Spin
                tip="Loading"
                style={{
                  marginTop: '1rem',
                  height: '44px',
                }}
              />
            ) : (
              <Button type="submit" disabled={!isFormValid()}>
                Apply now
              </Button>
            )}
          </form>
        </div>
      )}
    </div>
  );
}

function SuccessMessage() {
  return (
    <div
      style={{
        marginInline: 'auto',
        maxWidth: 846,
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <h1
        style={{
          color: 'var(--secondary-text-color)',
          fontSize: 48,
          fontWeight: 600,
          lineHeight: '150%',
        }}
      >
        Thank you for applying
      </h1>
      <p
        style={{
          fontSize: 16,
          fontWeight: 400,
          lineHeight: '200%',
        }}
      >
        Your application has been successfully submitted. We appreciate your interest in joining our
        team. Our hiring team will carefully review your application and qualifications. Should your
        skills and experience align with our requirements, we will reach out to you directly to
        proceed with the next steps of the selection process
      </p>
      <p
        style={{
          fontSize: 16,
          fontWeight: 400,
          lineHeight: '200%',
        }}
      >
        Thank you again for considering PandaTech as your potential employer. We wish you the best
        of luck with your application.
      </p>
    </div>
  );
}
